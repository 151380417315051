import React from 'react'
import Table from '../components/table'
import Hero from '../components/hero'
import Layout from '../components/layout'

interface Props {}

const Index: React.FC = () => {
  return (
    <Layout header footer>
      <Hero />
      <Table />
    </Layout>
  )
}

export default Index
