import React from 'react'

const Hero: React.FC = () => {
  return (
    <div className="">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-blue-900 sm:text-4xl sm:leading-10">
          Joe Biden vs Bernie Sanders
          <br />
          <span className="text-red-500">Let's see what sets them apart</span>
        </h2>
        <div className="flex justify-center mt-8 mb-8">
          <div className="inline-flex rounded-md shadow">
            <img className="h-40 w-48 bg-contain rounded overflow-hidden" src="biden-speech.jpg" alt="Joe Biden" />
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <img
              className="h-40 w-48 bg-contain rounded overflow-hidden"
              src="bernie-speech.jpg"
              alt="Bernie Sanders"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
