export interface Data {
  policy: string
  joe: boolean
  joeText?: string
  bernie: boolean
  bernieText?: string
  page?: string
}

export const data: Array<Data> = [
  // {
  //   policy: "Polls show beating Trump",
  //   joe: true,
  //   joeText: "Yes",
  //   bernie: true,
  //   bernieText: "Yes",
  //   page: "/issues/beating-trump"
  // },
  {
    policy: 'Existential Climate Threat',
    joe: false,
    joeText: 'Downplays',
    bernie: true,
    bernieText: 'Realizes',
    page: '/issues/climate-threat'
  },
  {
    policy: 'Full support for costly Iraq War',
    joe: true,
    bernie: false,
    page: '/issues/iraq-war'
  },
  {
    policy: 'Full support for costly war in Afghanistan',
    joe: true,
    bernie: false,
    page: '/issues/war-in-afghanistan'
  },
  {
    policy: 'Gutting Social Security and Medicaid',
    joe: true,
    bernie: false,
    page: '/issues/social-security'
  },
  {
    policy: 'Gaffes and Flubs',
    joe: true,
    joeText: 'Many',
    bernie: false,
    bernieText: 'Clean',
    page: '/issues/gaffes'
  },
  {
    policy: 'Green New Deal',
    joe: true,
    joeText: 'Sorta',
    bernie: true,
    page: '/issues/green-new-deal'
  },
  {
    policy: 'Legalize marijuana federally and stop incarcerating non-violent offenders ',
    joe: false,
    bernie: true,
    page: '/issues/marijuana'
  },
  {
    policy: 'No campaign finances from billionaires, only small donor donations',
    joe: false,
    bernie: true,
    page: '/issues/small-donations'
  },
  {
    policy: 'No campaign PAC money',
    joe: false,
    bernie: true,
    page: '/issues/no-pac-money'
  },
  {
    policy: 'End the filibuster',
    joe: false,
    bernie: true,
    page: '/issues/filibuster'
  },
  {
    policy: 'Ending US military interventions abroad and endless wars',
    joe: false,
    bernie: true,
    page: '/issues/ending-military-abroad'
  },
  {
    policy: 'Ban all fracking',
    joe: false,
    bernie: true,
    page: '/issues/ban-fracking'
  },
  {
    policy: 'End private prisons',
    joe: false,
    bernie: true,
    page: '/issues/end-private-prisons'
  },
  {
    policy: 'Break up the too big to fail banks',
    joe: false,
    bernie: true,
    page: '/issues/break-up-banks'
  },
  {
    policy: 'Medicare for all',
    joe: false,
    bernie: true,
    page: '/issues/medicare-for-all'
  },
  {
    policy: 'Expanding social security',
    joe: false,
    bernie: true,
    page: '/issues/expand-social-security'
  },
  {
    policy: 'Student debt reform',
    joe: false,
    bernie: true,
    page: '/issues/student-debt-reform'
  },
  // {
  //   policy: 'Close corporate tax loopholes',
  //   joe: false,
  //   bernie: true
  // },
  {
    policy: 'Political campaign finance reform',
    joe: false,
    bernie: true,
    page: '/issues/campaign-finance-reform'
  },
  // {
  //   policy: 'Open, accessible elections',
  //   joe: false,
  //   bernie: true
  // },
  {
    policy: 'Significantly reduce military spending',
    joe: false,
    bernie: true,
    page: '/issues/reduce-military-spending'
  },
  // {
  //   policy: 'Revising Bankruptcy Laws',
  //   joe: false,
  //   bernie: true
  // },
  {
    policy: 'Worker ownership to create corporate accountability',
    joe: false,
    bernie: true,
    page: '/issues/worker-ownership'
  },
  {
    policy: 'Repealing the Patriot Act',
    joe: false,
    bernie: true,
    page: '/issues/patriot-act'
  },
  {
    policy: 'Support of NAFTA - the job-killing China trade bill',
    joe: true,
    bernie: false,
    page: '/issues/china-trade-bill'
  },
  {
    policy: 'Support TPP',
    joe: true,
    bernie: false,
    page: '/issues/tpp'
  },
  {
    policy: 'Let all prisoners vote',
    joe: false,
    bernie: true,
    page: '/issues/prisoners-vote'
  },
  {
    policy: 'Abolish Billionaires',
    joe: false,
    joeText: 'No',
    bernie: true,
    bernieText: 'Pretty Much',
    page: '/issues/abolish-billionaires'
  },
  {
    policy: 'Consider eliminating the electoral college',
    joe: false,
    bernie: true,
    page: '/issues/electoral-college'
  },
  {
    policy: 'Eliminate Capital Punishment',
    joe: true,
    bernie: true,
    page: '/issues/capital-punishment'
  },
  // {
  //   policy: 'Focus deportation on criminals and national security threats',
  //   joe: false,
  //   bernie: true
  // },
  // {
  //   policy: 'Guarantee more than 12 weeks of paid family leave',
  //   joe: false,
  //   bernie: true
  // },
  {
    policy: 'Enact a wealth tax',
    joe: false,
    bernie: true,
    page: '/issues/wealth-tax'
  },
  // {
  //   policy: 'Staff administration with demographic distribution that represents all of us',
  //   joe: false,
  //   bernie: true
  // },
  // {
  //   policy: 'The United States should consider a jobs guarantee',
  //   joe: false,
  //   bernie: true
  // },
  // {
  //   policy: "Bussing for desegregation",
  //   joe: false,
  //   bernie: true,
  // },
  // {
  //   policy: "Personal controversies",
  //   joe: true,
  //   joeText: "Many",
  //   bernie: false,
  //   bernieText: "Few",
  // },
  {
    policy: 'Young voter support',
    joe: false,
    joeText: 'Almost none',
    bernie: true,
    bernieText: 'Lots',
    page: '/issues/young-voter-support'
  }
]
